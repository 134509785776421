<!--
 * @Description: 
 * @Author: 
 * @Date: 2022-11-22 09:47:55
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-05-08 16:55:21
-->
<template>
  <div style="height: 100%">
    <!-- 卡片视图 -->
    <el-card style="height: 100%;overflow:auto;">
      <!-- 面包屑导航 -->
    <breadcrumb-nav>
      <template v-slot:firstMenu>系统管理</template>
      <template v-slot:secondMenu>员工列表</template>
    </breadcrumb-nav>
    <div style="fontSize: 20px;fontWeight: 400;color: #0a0b1a;">
        员工列表
      </div>
      <!-- 添加员工 -->
      <div class="radioBox">
      <user-add @role-list='updateRoleList' />
      </div>
      <!-- 表格 -->
      <!-- <role-table :roles-list="rolesList" @role-list="updateRoleList" /> -->
      <user-table :roles-list="rolesList" @role-list='updateRoleList' :roleList="roleList"/>
      <div style="float: right;">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryInfo.pageNum"
            :page-sizes="[10, 20, 30, 50]"
            :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>

  // 导入公共组件
  import BreadcrumbNav from "../common/BreadcrumbNav";
import { getRolesListRequest } from 'network/user'


  // 导入子组件
  import UserAdd from './UserComponents/UserAdd.vue';
  import UserTable from './UserComponents/UserTable.vue';

  // 导入发送网络请求的函数
  import {getUserListRequest} from 'network/user.js'


  export default {
    name: "Roles",
    components:{
      BreadcrumbNav,
      UserAdd,
      UserTable
    },
    data() {
      return {
        rolesList: [],
        queryInfo: {
          query: "",
          pageNum: 1,
          pageSize: 10
        },
        total: 0,
        roleList: []
      }
    },
    created() {
      this.getRolesList();
      this.getRole()
    },
    methods: {
      getRole() {
        getRolesListRequest({
          enterpriseId: localStorage.getItem('enterpriseId'),
        }).then(res => {
            console.log(res)
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取权限数据失败', 'error');
          }
          this.roleList = result.data;
        })
      },
      // 获取员工列表
      getRolesList() {
        let queryInfos={
          enterpriseId: localStorage.getItem('enterpriseId'),
          pageNumber: this.queryInfo.pageNum,
          pageSize: this.queryInfo.pageSize
        }
        getUserListRequest(queryInfos).then(res => {
          console.log(res)
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取角色列表失败', 'error');
          }
          this.rolesList = result.data.list;
          this.total = result.data.total;
        })
      },

      // 每页显示的数据条数发送变化
      handleSizeChange(newSize) {
        this.queryInfo.pageNum = 1
        this.queryInfo.pageSize = newSize;
        this.getRolesList();
      },

      // 当前页码发生变化
      handleCurrentChange(newPage) {
        this.queryInfo.pageNum = newPage;
        this.getRolesList();
      },

      // 监听子组件中发出的事件，重新获取角色列表
      updateRoleList(){
        this.getRolesList();
      }
    }
  }
</script>

<style scoped>
.radioBox{
  margin-top: 30px;
  margin-bottom: 15px;
}
</style>
