<template>
  <el-button type="text"  @click="removeRoleById(roleId)">删除</el-button>
</template>

<script>
  import {removeRoleByIdRequest} from 'network/rights'
  import {removeUserByIdRequest} from 'network/user'

  export default {
    name: "RoleRemove",
    props: {
      roleId: {
        type: Number,
        required: true
      }
    },
    methods: {
      // 根据ID删除用户
      removeRoleById(id) {
        console.log(id);
        this.$confirm('此操作将删除该员工, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          removeUserByIdRequest(
            {
              id: id,
              enable: 2
            }
          ).then(res => {
            let result = res.data;

            if (result.code !== 200) {
              return this.alertMessage('删除失败', 'error');
            }

            this.alertMessage('删除成功', 'success');

            this.$emit('role-list');
          });
        }).catch(() => {
          this.alertMessage('已取消删除','info');
        });
      }
    }
  }
</script>

<style scoped>

.el-button--text{
  color: var(--theme_bg_color);
}
  .el-button:focus,
  .el-button:hover {
    border: 0px;
    border-color: #fff;
    color: var(--theme_bg_color);
  }
.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}
.el-button--primary:focus,.el-button--primary:hover {
    border-color: var(--theme_bg_color);
    color: #fff;
  }

</style>
