/*
 * @Description: 
 * @Author: 
 * @Date: 2022-11-22 09:47:55
 * @LastEditors: 
 * @LastEditTime: 2023-04-07 09:49:05
 */
// 用户管理中的网络请求

import {request} from "./request";

// 获取用户列表
export function getUserListRequest(queryInfo) {
  return request({
    url: 'platform/sysuser/queryUserList',
    method: 'get',
    params: {...queryInfo}
  })
}

// 获取角色列表
export function getRolesListRequest(queryInfo) {
  return request({
    url: 'platform/role/roleList',
    method: 'get',
    params: {...queryInfo}
  })
}

// 修改用户状态
export function changUserStateRequest(uid, type) {
  return request({
    url: `users/${uid}/state/${type}`,
    method: 'put',
  })
}

// 添加新用户
export function addUserRequest(userinfo) {
  return request({
    url: 'platform/sysuser/addEnterpriseUser',
    method: 'post',
    data: {...userinfo}
  })
}

// 根据ID查询用户信息
export function getUserByIdRequest(id) {
  return request({
    url: `/users/${id}`
  })
}

// 编辑用户
export function editUserByIdRequest(userInfo) {
  return request({
    url: 'platform/sysuser/updateEnterpriseUser',
    method: 'post',
    data: {...userInfo}
  })
}

// 删除用户
export function removeUserByIdRequest(userInfo) {
  return request({
    url: 'platform/sysuser/enableUser',
    method: 'post',
    params: {...userInfo}
  })
}

// 重置密码
export function resetUserRequest(userInfo){
  return request({
    url: 'platform/sysuser/updatePassword',
    method: 'post',
    params: {...userInfo}
  })
}
