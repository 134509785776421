<template>
  <div>
    <!-- 按钮 -->
    <el-row>
      <el-col>
        <el-button type="primary" @click="addDialogVisible = true">添加员工</el-button>
      </el-col>
    </el-row>

    <!-- 添加角色的对话框 -->
    <el-dialog
            title="新建员工"
            :visible.sync="addDialogVisible"
            width="40%"
            @close="addDialogClose">
      <el-form :model="addUserForm" :rules="roleFormRules" ref="addUserForm" label-width="80px">
        <el-form-item label="用户姓名" prop="fullName">
          <el-input v-model="addUserForm.fullName" placeholder="请输入用户姓名" maxlength="10" style="width: 500px"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="adminPhone">
          <el-input v-model="addUserForm.adminPhone" placeholder="请输入手机号" maxlength="11" style="width: 500px"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="adminPassword">
          <el-input v-model="addUserForm.adminPassword" placeholder="请输入密码" minlength="6" show-password style="width: 500px"></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="roleId">
          <el-select v-model="addUserForm.roleId" placeholder="请选择角色" style="width: 500px">
            <el-option v-for="item in roleList" :key="item.id" :label="item.roleName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addRole()">确 定</el-button>
      </span>
    </el-dialog>
  </div>

</template>

<script>
  import { getRolesListRequest,addUserRequest } from 'network/user'
  export default {
    name: "RoleAdd",
    data() {
      return {
        roleList: [],
        defaultKeys:[],
        addDialogVisible:false,
        addUserForm: {
          fullName: '',
          adminPhone: '',
          adminPassword: '',
          roleId: '',
        },
        roleFormRules: {
          fullName: [
            {required: true, message: '请输入用户姓名', trigger: 'blur'},
          ],
          adminPhone: [
            {required: true, message: '请输入手机号', trigger: 'blur'},
          ],
          adminPassword: [
            {required: true, message: '请输入密码', trigger: 'blur'},
          ],
          roleId: [
            { required: true, message: '请选择角色', trigger: 'change' }
          ],
        },
      }
    },
    created() {
      this.getRole();
    },
    methods:{
      getRole() {
        getRolesListRequest({
          enterpriseId: localStorage.getItem('enterpriseId'),
        }).then(res => {
          console.log(res,854);
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取权限数据失败', 'error');
          }
          this.roleList = result.data;
        })
      },
      // 添加角色
      addRole() {
        this.$refs.addUserForm.validate((valid) => {
          if (valid) {
            let newData = {
              ...this.addUserForm,
              tardId: localStorage.getItem('enterpriseId'),
              tardName: localStorage.getItem('enterpriseName'),
              type: localStorage.getItem('type')
            }
            addUserRequest(newData).then(res => {
            let result = res.data;
            if (result.code !== 200) {
              return this.alertMessage(result.message, 'error');
            }
            this.alertMessage('添加员工成功', 'success');

            // 隐藏弹窗
            this.addDialogVisible = false;
            this.$refs.addUserForm.resetFields();
            // 重新获取角色列表
            this.$emit('role-list');
          })

          }
        });
      },

      // 监听添加角色的弹框的关闭事件
      addDialogClose() {
        this.$refs.addUserForm.resetFields();
      },
    }
  }
</script>

<style scoped>
.el-button--text{
  color: var(--theme_bg_color);
}
  .el-button:focus,
  .el-button:hover {
    border: 0px;
    border-color: #fff;
    color: var(--theme_bg_color);
  }
.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}
.el-button--primary:focus,.el-button--primary:hover {
    border-color: var(--theme_bg_color);
    color: #fff;
  }

</style>
