<!--
 * @Description: 
 * @Author: 
 * @Date: 2022-11-22 09:47:55
 * @LastEditors: koushaoping 1719422019@qq.com
 * @LastEditTime: 2023-12-11 09:58:12
-->
<template>
  <el-table highlight-current-row :data="rolesList" border :header-cell-style="{color: '#848484', fontSize: '14px', height:'22px',  background: '#f7f7f7'}" :cell-style="{color: '#0a0b1a'}">
    <el-table-column prop="fullName" label="姓名"></el-table-column>
    <el-table-column prop="username" label="账号"></el-table-column>
    <el-table-column prop="roleName" label="角色"></el-table-column>
    <el-table-column prop="createDate" label="创建时间"></el-table-column>
    <el-table-column label="操作" width="300px">
      <template slot-scope="scope">
        <!-- 编辑按钮 -->
        <user-edit :userScope="scope.row" @role-list="updateRoleList" :roleList="roleList"/>

        <!-- 删除按钮 -->
        <user-remove :role-id="scope.row.id" @role-list="updateRoleList" />

        <!-- 重置密码 -->
        <user-reset :role-id="scope.row.id" @role-list="updateRoleList"/>
      </template>
    </el-table-column>
  </el-table>
  
</template>

<script>
import UserEdit from './UserEdit.vue';
import UserRemove from './UserRemove.vue';
import UserReset from './UserReset.vue';


  export default {
    name: "RoleTable",
    props: {
      rolesList: {
        type: Array,
        default() {
          return []
        }
      },
      roleList: {
        type: Array
      }
    },
    data () {
      return {
        
        
      }
    },
    components: {
        UserEdit,
        UserRemove,
        UserReset
    },
    methods: {
      
      // 监听子组件中发出的事件，重新获取角色列表
      updateRoleList() {
        this.$emit('role-list');
      }
    }
  }
</script>

<style scoped>

</style>
