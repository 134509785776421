<template>
  <div class="editDialog">
    <el-button type="text"  @click="showEditDialog(userScope)">编辑</el-button>

    <!-- 修改角色的对话框 -->
    <el-dialog
            title="修改用户"
            :visible.sync="editDialogVisible"
            width="50%"
            @close="editDialogClose">
      <el-form :model="editUserForm" :rules="roleFormRules" ref="editUserForm" label-width="80px">
        <el-form-item label="用户姓名" prop="fullName">
          <el-input v-model="editUserForm.fullName" placeholder="请输入用户姓名" style="width: 500px"></el-input>
        </el-form-item>
        <el-form-item label="账号" prop="adminPhone">
          <el-input v-model="editUserForm.adminPhone" placeholder="请输入账号" style="width: 500px" disabled></el-input>
        </el-form-item>

        <el-form-item label="角色" prop="roleId">
          <el-select v-model="editUserForm.roleId" placeholder="请选择角色" style="width: 500px">
            <el-option v-for="item in roleList" :key="item.id" :label="item.roleName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editRole">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { getRolesListRequest,editUserByIdRequest } from 'network/user'


  export default {
    name: "RoleEdit",
    props: {
      userScope: {
        type: Object,
      },
      roleList: {
        type: Array
      }
    },
    data() {
      return {
        
        userId: '',
        userRoleId: '',
        editDialogVisible: false,
        editUserForm: {
          fullName: '',
          adminPhone: '',
          roleId: '',
        },
        roleFormRules: {
          fullName: [
            {required: true, message: '请输入角色名称', trigger: 'blur'},
          ],
          adminPhone: [
            {required: true, message: '请输入角色描述', trigger: 'blur'},
          ],
          roleId: [
            { required: true, message: '请选择角色', trigger: 'change' }
          ],
        },
      }
    },
    methods: {
      
      // 展示编辑角色的弹窗
      showEditDialog(scope) {
        this.editDialogVisible = true;
        console.log(scope);
        let newForm = {
          fullName: scope.fullName,
          adminPhone: scope.username,
          roleId: scope.roleId,
        }
        this.editUserForm = newForm
        this.userId = scope.id
        this.userRoleId = scope.userRoleId
      },

      // 监听编辑角色的弹框的关闭事件
      editDialogClose() {
        this.$refs.editUserForm.resetFields();
      },

      // 点击编辑角色弹框的“确认”按钮提交
      editRole() {
        this.$refs.editUserForm.validate(valid => {
          if (!valid) return;
          editUserByIdRequest(
            {
              ...this.editUserForm,
              userId: this.userId,
              userRoleId: this.userRoleId
            }
          ).then(res => {
            let result = res.data;

            if (result.code !== 200) {
              return this.alertMessage('修改失败', 'error');
            }
            this.alertMessage('修改成功', 'success');

            // 隐藏弹窗
            this.editDialogVisible = false;

            // 重新获取角色列表
            this.$emit('role-list');
          })
        })
      }
    }
  }
</script>

<style scoped>
.editDialog {
  display: inline-block;
  margin-right: 5px;
}

.el-button--text{
  color: var(--theme_bg_color);
}
  .el-button:focus,
  .el-button:hover {
    border: 0px;
    border-color: #fff;
    color: var(--theme_bg_color);
  }
.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}
.el-button--primary:focus,.el-button--primary:hover {
    border-color: var(--theme_bg_color);
    color: #fff;
  }
</style>
